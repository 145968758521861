<template>
  <div class="layer_02_mission">
    <div class="layer_top">
      <h2 class="layer_top_title">회원찾기</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="call_list">
      <div class="call_list_search">
        <form @submit="onSearch">
          <label for="search" class="layer_tbl_title pb_15 mr_30">
            회원 아이디
          </label>
          <input
            id="search"
            type="text"
            placeholder="검색어를 입력해주세요."
            class="list_search_box ml_04"
            v-model="search"
          />
          <base-button class="ml_10" type="upload" nativeType="submit">
            검색
          </base-button>
        </form>
      </div>
      <div>
        <base-table
          :useNo="true"
          :totalItems="totalItems"
          :currentPage="currentPage"
          :pageSize="pageSize"
          :loading="contentLoading"
          :fields="fields"
          :items="items"
          :isEdit="false"
        >
          <template v-slot:id="slotProps">
            <span style="display: none">{{ slotProps }}</span>
            <base-button type="upload" class="height_40">
              획득처리
            </base-button>
          </template>
        </base-table>
      </div>
      <base-pagination
        v-if="totalPages > 1"
        :total="totalItems"
        :loading="contentLoading"
        :currentPage="currentPage"
        :pageCount="totalPages"
        :perPage="pageSize"
        @change="fetch"
      />
    </div>
    <div class="btn_center_wrapper mb_30">
      <base-button type="primary" @click="$emit('close')">확인</base-button>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BasePagination from "@/components/core/BasePagination.vue";
// import Dialog from "@/components/Dialog.vue";

export default {
  components: {
    BaseButton,
    BaseTable,
    BasePagination,
  },
  props: {
    userId: {
      type: Number,
    },
  },
  data() {
    return {
      checkedUserId: "",
      fields: [
        {
          key: "loginid",
          width: "100px",
          label: "아이디",
        },
        {
          key: "email",
          width: "140px",
          label: "이메일주소",
        },
        // {
        //   key: "tel",
        //   width: "140px",
        //   label: "전화번호",
        // },
        {
          key: "registerDate",
          width: "140px",
          label: "가입일시",
        },
        {
          key: "id",
          label: "스탬프 획득처리",
          width: "",
          useSlot: true,
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      search: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },
    async fetch(page = 1) {
      this.currentPage = page;
      this.contentLoading = true;
      let params = {
        page: this.currentPage,
        size: this.pageSize,
      };
      await client.generalUserFindAll(params).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.items = data.items;
          this.totalItems = data.totalItems;
          this.totalPages = data.totalPages;
          this.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.items = [];
          this.totalItems = 0;
          this.totalPages = 0;
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.paging {
  margin-bottom: 15px;
}
</style>
