<template>
  <div class="manage_layer_03">
    <div class="layer_top">
      <h2 class="layer_top_title">
        {{ status == 1 ? "일반 스탬프" : "리워드 스탬프" }}
      </h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_03">
      <form>
        <table>
          <colgroup>
            <col style="width: 130px" />
            <col style="width: 320px" />
            <col style="width: 130px" />
            <col style="width: 270px" />
            <col style="width: 130px" />
            <col style="width: 270px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">스탬프 명</td>
            <td class="box_style_02">
              {{ content.title }}
            </td>
            <td class="box_style_02 list_title_style">운영기간</td>
            <td class="box_style_03">
              {{ periodDay }}
            </td>
            <td class="box_style_02 list_title_style" v-if="status == 1">
              스탬프 레벨
            </td>
            <td class="box_style_02" v-if="status == 1">
              {{ level }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style" v-if="status == 1">
              위도
            </td>
            <td class="box_style_02" v-if="status == 1">
              {{ lat }}
            </td>
            <td class="box_style_02 list_title_style" v-if="status == 1">
              경도
            </td>
            <td class="box_style_02" v-if="status == 1">
              {{ lng }}
            </td>
            <td class="box_style_02 list_title_style" v-if="status == 1">
              반경
            </td>
            <td class="box_style_02" v-if="status == 1">
              {{ `${gpsRadius}m` }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style" v-if="status == 1">
              GPS 획득
            </td>
            <td class="box_style_02" v-if="status == 1">
              {{ gpsState }}
            </td>
            <td class="box_style_02 list_title_style" v-if="status == 1">
              QR Code 획득
            </td>
            <td class="box_style_02" v-if="status == 1">
              {{ qrcodeState }}
            </td>
            <td class="box_style_02 list_title_style" v-if="status == 1">
              재도전 횟수
            </td>
            <td class="box_style_02" v-if="status == 1">{{ relationLevel }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style" v-if="status == 1">
              주소
            </td>
            <td class="box_style_03" v-if="status == 1">
              {{ content.address }}
            </td>
            <td class="box_style_02 list_title_style" v-if="status == 1">
              GPS 위도
            </td>
            <td class="box_style_02" v-if="status == 1">
              {{ gpsLat }}
            </td>
            <td class="box_style_02 list_title_style" v-if="status == 1">
              GPS 경도
            </td>
            <td class="box_style_02" v-if="status == 1">
              {{ gpsLng }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style" v-if="picture.name">
              스탬프
            </td>
            <td class="box_style_02" v-if="picture.name">
              <img
                :src="`${picture.url}${picture.path}`"
                alt="스탬프"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
            <td class="box_style_02 list_title_style" v-if="cover.name">
              스탬프 커버
            </td>
            <td class="box_style_02" v-if="cover.name">
              <img
                :src="`${cover.url}${cover.path}`"
                alt="스탬프 커버"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
            <td class="box_style_02 list_title_style">스탬프 획득</td>
            <td class="box_style_02" colspan="3">
              <base-button type="upload" class="gps_btn" @click="userCall">
                사용자 목록 보기
              </base-button>
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">짧은 설명</td>
            <td class="box_style_03" colspan="7">
              {{ content.shortDescription }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">긴 설명</td>
            <td class="box_style_03" colspan="7">
              {{ content.description }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
import StampUserCall from "@/components/StampUserCall.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    status: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      content: {
        title: "",
        address: "",
        description: "",
        shortDescription: "",
      },
      cover: {},
      picture: {},
      lat: "",
      lng: "",
      gpsLat: "",
      gpsLng: "",
      gpsRadius: "",
      gpsState: "",
      qrcodeState: "",
      periodDay: "",
      relationLevel: 1,
      level: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.stampFindOne(this.id).then(
        (response) => {
          const { data } = response;
          this.content.title = data.content.title;
          this.content.address = data.content.address;
          this.content.shortDescription = data.content.shortDescription;
          this.content.description = data.content.description;
          this.lat = data.lat;
          this.lng = data.lng;
          try {
            this.periodDay = `${this.$d(
              new Date(data.periodStartDay).getTime(),
              "short"
            )} ~ ${this.$d(new Date(data.periodEndDay).getTime(), "short")}`;
          } catch (e) {
            if (e) {
              this.periodDay = "-";
            }
          }
          this.relationLevel = data.relationLevel;
          this.level = data.level;
          this.gpsState = data.gpsState == 1 ? "획득 가능" : "획득 불가능";
          this.qrcodeState =
            data.qrcodeState == 1 ? "획득 가능" : "획득 불가능";
          this.gpsLat = data.gpsLat;
          this.gpsLng = data.gpsLng;
          this.gpsRadius = data.gpsRadius;
          this.picture = data.picture;
          this.cover = data.cover;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    userCall() {
      this.$modal.show(
        StampUserCall,
        {},
        {
          width: 804,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              this.fetchUserId(response.params);
            }
          },
        }
      );
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
