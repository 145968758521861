<template>
  <div class="select">
    <select @change="handleChange" :value="value">
      <option value="">{{ placeholder }}</option>
      <template v-if="optionsType === 'string'">
        <option :value="option" v-for="(option, index) in options" :key="index">
          {{ option }}
        </option>
      </template>

      <template v-else>
        <option
          :value="option.value"
          v-for="(option, index) in options"
          :key="index"
          :selected="picked == option.name"
        >
          {{ option.name }}
        </option>
      </template>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: [Array],
      default: () => [],
    },
    value: {
      default: "",
    },
    placeholder: {
      type: String,
      default: "전체",
    },
    picked: {
      type: String,
      default: "",
    },
  },
  computed: {
    optionsType() {
      let ot = "string";
      if (this.options.length > 0 && typeof this.options[0] !== "string") {
        ot = "object";
      }
      return ot;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>
